import { combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import commonReducers from '../../common/reducers';
import createRequests from '../../common/services/create-requests';
import createStore from '../../common/services/create-store';
import categoryMenuWidgetReducer from '../aggregated-category-menu/reducer';

export function createReduxStore({
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  bundleName,
  flowAPI,
}) {
  const p = {};

  const { request, platformizedRequest, aggregatorRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore: () => p.store,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      aggregatorRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
    }),
  ];

  const reducers = combineReducers({
    ...commonReducers,
    categoryMenuWidget: categoryMenuWidgetReducer,
  });

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
