import { sortBy } from 'lodash';
import { resolveId, upsertBy } from '@wix/communities-blog-client-common';
import { ADD_CATEGORY } from './add-category';
import { CREATE_CATEGORY_SUCCESS } from './create-category-types';
import { FETCH_CATEGORIES_SUCCESS } from './fetch-categories';

const SORT_BY = 'rank';

const categories = (state = [], { type, payload } = {}) => {
  switch (type) {
    case FETCH_CATEGORIES_SUCCESS:
      return sortBy(payload, SORT_BY);

    case ADD_CATEGORY:
    case CREATE_CATEGORY_SUCCESS:
      return sortBy(upsertBy(state, payload, resolveId), SORT_BY);

    default:
      return state;
  }
};

export default categories;
