import type { Dispatch } from 'redux';
import type { IWixAPI } from '@wix/yoshi-flow-editor';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  routerDataServiceFactory,
  type IMembersAreaWidgetPluginService,
} from '@wix/members-area-widget-plugin-lib/viewer';
import {
  createAction,
  getCurrentUserSiteMemberId,
} from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import getEnvironment from '../../../common/services/get-environment';
import type { GetState } from '../../../common/types';

export const setViewedUser = createAction('viewedUser/SET');

export const fetchViewedUser =
  (widgetPluginService: IMembersAreaWidgetPluginService) =>
  async (
    dispatch: Dispatch,
    getState: GetState,
    { wixCodeApi }: { wixCodeApi: IWixAPI },
  ) => {
    const { isPreview, isEditor } = getEnvironment(wixCodeApi);
    const isMAPluginServiceEnabled =
      getState().experiments[OOI_EXPERIMENTS.FIX_MY_POSTS_USER_ID] === 'true';

    if (isMAPluginServiceEnabled) {
      const memberIds = await getMemberIds(wixCodeApi, widgetPluginService);
      const userId =
        isEditor || isPreview
          ? memberIds.currentMemberId
          : memberIds.viewedMemberId;
      dispatch(setViewedUser(userId));
      return;
    }

    if (isEditor || isPreview) {
      const currentUserSiteMemberId = getCurrentUserSiteMemberId(getState());
      dispatch(setViewedUser(currentUserSiteMemberId));
      return;
    }

    const api = await wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID);
    const viewedUser = await api.getViewedUser();
    dispatch(setViewedUser(viewedUser));
  };

const getMemberIds = async (
  wixCodeApi: IWixAPI,
  widgetPluginService: IMembersAreaWidgetPluginService,
) => {
  const { window, user } = wixCodeApi;
  const routerDataService = routerDataServiceFactory(
    widgetPluginService,
    user,
    window,
  );

  const routeData = await routerDataService.getRouteData();

  const currentMemberId = routeData?.currentMemberIdentifier?.id ?? null;
  const viewedMemberId = routeData?.viewedMemberIdentifier?.id ?? null;

  return { currentMemberId, viewedMemberId };
};
